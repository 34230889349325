import { createContext } from "react"

export const Store = createContext()

export const initialState = {
  token: {
    value:
      "",
    alternate: ""
  },
  home: {
    cpf: "",
    phone: "",
    email: ""
  },
  terms: {
    agree: false
  },
  address: {
    cep: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    uf: ""
  },
  docs: {
    type: ""
  },
  callback: {
    url: ""
  },
}

export const LoginStore = createContext()

export const loginInitialState = {
  username: "",
  password: "",
  token: ""
}
