import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: ${props => props.theme.palette.primary};
  }

  p {
    font-size: 1.4rem;
    text-align: center;
    background-color: ${props => props.theme.palette.gray[100]};
    color: ${props => props.theme.palette.gray[700]};
    border-radius: ${props => `${props.theme.radius}rem`};
    padding: 2rem;
    margin-top: 3rem;
    line-height: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }
`
