import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .hint {
      font-size: 1rem;
      margin-top: 1rem;
      margin-top: 1rem;
      background-color: #fff3cd;
      color: #b55e00;
      padding: 1.4rem;
      border-radius: 0.8rem;
      text-align: center;
      line-height: 1.5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      .hint {
        font-size: 1.1rem;
      }
    }
  }
`
