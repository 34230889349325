import styled from "styled-components"

export default styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    height: 10rem;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 1.8rem;
    margin: 2rem 0%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.2em;
    color: ${props => props.theme.palette.gray[500]};
    font-weight: 500;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 1.4rem;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .logo {
      height: 15rem;
    }
  }

  .MuiInput-input {
    font-size: 1.4rem;
  }
`
