import React, { useEffect, useState } from "react"

export default function({ time, callback }) {
  const calculateTimeLeft = () => {
    const difference = +time - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return function cleanup() {
        clearTimeout(timer)
    };
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    timerComponents.push(timeLeft[interval]);
  });

  const formatTime = () => {
    let formattedTime = ''
    for (let comp of timerComponents) {
        formattedTime += `${formattedTime !== '' ? ':' : ''}${comp.toString().padStart(2,'0')}`
    }
    return formattedTime
  }

  return (
    <div className="countdown_timer">
      {timerComponents.length ? formatTime(timerComponents) : callback()}
    </div>
  );
}