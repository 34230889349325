import React, { useContext, useState } from "react"
import * as Yup from "yup"
import { Formik, Field } from "formik"
import jwtDecode from "jwt-decode"
import axios from "axios"
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/dynamic"

import Container from "./styles"
import { Button } from "components/Button"
import { MaskedInput } from "components/MaskedInput"
import { TextInput } from "components/TextInput"
import { Store, LoginStore } from "store"
import { validationMessages } from "utils/messages"
import { cpfValidator, phoneValidator } from "utils/validators"
import { verifyTokenRole } from "config/tokenRouter"
import { roles } from "config/roles"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { baseUrl } from "api/api"


const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .required(validationMessages.required)
    .test("valid", validationMessages.invalidCpf, val => {
      if (val) return cpfValidator(val)
      return false
    }),
  phone: Yup.string()
    .required(validationMessages.required)
    .test("valid", validationMessages.invalidPhone, val => {
      if (val) return phoneValidator(val)
      return false
    }),
  email: Yup.string()
    .email(validationMessages.invalidEmail)
    .required(validationMessages.required)
})

export default props => {
  const [{ home }, dispatch] = useContext(Store)
  const [{ token }] = useContext(LoginStore)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    cpf: home.cpf,
    phone: home.phone,
    email: home.email
  }

  const handleSubmit = async () => {
    setLoading(true)
    const res = await axios({
      method: "POST",
      url: `${baseUrl}/new_register`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      data: home
    })

    if (res.data) {
      const { token } = res.data
      const { identity } = jwtDecode(token)

      if (identity.role === roles.CAN_VERIFY_PHONE) {
        const myInsistentTimer = setIntervalAsync(async () => {
          const res = await axios({
            method: "GET",
            url: `${baseUrl}/sms_validate`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          })

          if (res) {
            if (res.status === 200) {
              dispatch({ type: SET_TOKEN, payload: res.data.token })
              verifyTokenRole(res.data.token, props.history)
              clearIntervalAsync(myInsistentTimer)
            }
          }
        }, 1000)
      }
    }
  }

  return (
    <React.Fragment>
      <h1>Cadastre-se</h1>

      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={form => {
            return (
              <form onSubmit={form.handleSubmit}>
                <Field name="cpf" label="CPF" component={MaskedInput} />
                <Field name="phone" label="Celular com DDD" component={MaskedInput} />
                <Field name="email" label="Email" component={TextInput} />

                <small className="hint">
                  Será enviado um link de confirmação para o email informado
                </small>

                <Button type="submit" loading={loading}>
                  Prosseguir
                </Button>
              </form>
            )
          }}
        />
      </Container>
    </React.Fragment>
  )
}
