function getEnv() {
  const baseEnv = {
    baseColor: "#222222",
    secondaryColor: "#00B200",
    contrastColor: "#ffffff",
    secondaryContrastColor: "#ffffff",
    localStorageKey: "onboarding_login_token",
    smsIdentificator: "A"
  };

  let url = "";

  // if (process.env.REACT_APP_ENV === "production") {
  url = "https://api.aqbank.fullonboarding.inovamind.app";
  // } else if (process.env.REACT_APP_ENV === "staging") {
  //   url = "https://fullonboarding-hml.inovamind.com.br"
  // } else {
  //   url = "https://fullonboarding-dev.inovamind.com.br"
  // }

  return {
    ...baseEnv,
    url
  };
}

export default getEnv();
