import tokenReducer from "./tokenReducer"
import fieldsReducer from "./fieldsReducer"
import callbackReducer from "./callbackReducer"

const rootReducer = (state, action) => ({
  token: tokenReducer(state.token, action),
  home: fieldsReducer(state.address, action),
  address: fieldsReducer(state.address, action),
  docs: fieldsReducer(state.address, action),
  callback: callbackReducer(state.callback, action),
})

export default rootReducer
