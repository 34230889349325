import React, { useContext, useState } from "react"
import axios from "axios"
import { CircularProgress } from '@material-ui/core'

import Container, { CustomModal } from "./styles"
import Icon from "components/Icons"
import { LoginStore } from "store"
import { baseUrl } from "api/api"

export default function({ history }) {

  const [{ token }] = useContext(LoginStore)
  const [modalOpen, setModalOpen] = useState(false)
  const [signature, setSignature] = useState(false)
  const [signatureLoading, setSignatureLoading] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  const handleButtonClick = url => {
    history.push(`/${url}`)
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
    setLinkCopied(false)
  }

  const generateExternalLink = async () => {
    setSignatureLoading(true)

    const res = await axios({
      method: "GET",
      url: `${baseUrl}/signature/generate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })

    const origin = document.location.origin

    const start = origin.substring(0, origin.indexOf('//')+2)
    const end = origin.substring(origin.indexOf('//')+2, origin.length)

    const signatureUrl = `${start}public.${end}${res.data.url}`

    setSignatureLoading(false)
    setSignature(signatureUrl)
    toggleModal()
  }

  const copyLink = () => {
    const el = document.getElementById("url")
    el.select()
    document.execCommand('copy')
    setLinkCopied(true)
  }

  return (
    <React.Fragment>
      <CustomModal
        open={modalOpen}
        onClose={toggleModal}
      >
        <h3>Link Externo</h3>
        <input type="text" id="url" className="url" value={signature} readOnly />

        <div className="buttons-container">
          <button className="primary-button" onClick={copyLink}>
            {!linkCopied ? (
              'Clique para copiar o link'
            ) : (
              <React.Fragment>
                Link copiado
                <Icon icon="check" />
              </React.Fragment>
            )}
          </button>
        </div>
      </CustomModal>
      <Container>
        <div className="container">
          <button className="moduleButton" onClick={() => handleButtonClick("home")}>
            <Icon icon="register" />
            Cadastro
          </button>
          <button className="moduleButton" onClick={generateExternalLink}>
            {!signatureLoading ? (
              <React.Fragment>
                <Icon icon="externalLink" />
                Link Externo
              </React.Fragment>
            ) : (
              <div className="loading-container">
                <CircularProgress className="loading" />
              </div>
            )}
            
          </button>
          <button className="moduleButton" onClick={() => handleButtonClick("dashboard")}>
            <Icon icon="dashboard" />
            Dashboard
          </button>
        </div>
      </Container>
    </React.Fragment>
  )
}
