import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 40rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
  }

  .logo {
    height: 15rem;
    margin-bottom: 2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .logo {
      height: 25rem;
    }
  }

  form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`
