import styled from "styled-components"
import { Dialog } from '@material-ui/core'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    margin-top: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: center;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .container {
      margin-top: 15rem;
    }
  }

  .moduleButton {
    border: 0;
    background-color: ${props => props.theme.palette.gray[300]};
    padding: 2rem;
    border-radius: ${props => `${props.theme.radius}rem`};
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    width: 15rem;
    transition: all 0.1s ease-in;
    cursor: pointer;
    white-space: nowrap;
    
    &:not(:last-child) {
      margin-right: 4rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    svg {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: ${props => props.theme.palette.secondary};
      color: ${props => props.theme.palette.secondaryContrast};
      box-shadow: ${props => props.theme.shadows[4]};

      .MuiCircularProgress-root {
        color: ${props => props.theme.palette.secondaryContrast};
      }
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    
  }
`

export const CustomModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    padding: 2rem;
    border-radius: ${props => `${props.theme.radius}rem`};
    font-size: 1.2rem;
    width: 70rem;

    h3 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.6rem;
    }

    .url {
      font-size: 1.6rem;
      font-weight: 500;
      color: ${props => props.theme.palette.secondary};
      border: 0;
      outline: none;
      padding: 2rem 0;
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      .primary-button {
        border: 0;
        background-color: ${props => props.theme.palette.secondary};
        border-radius: ${props => `${props.theme.radius}rem`};
        font-size: 1.4rem;
        font-weight: 500;
        color: ${props => props.theme.palette.secondaryContrast};
        outline: none;
        cursor: pointer;
        padding: 2rem;
        text-transform: uppercase;
        width: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 0.5rem;
          height: 16px !important;
        }
      }
    }

  }
`
