import styled from "styled-components"

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 2.4rem;
      margin: 2rem 0%;
      text-align: center;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.8rem;
      }
    }
`
