import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

  .fileContainer {
    width: 100%;
    background-color: ${props => props.theme.palette.gray[100]};
    border-radius: ${props => `${props.theme.radius}rem`};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .select-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.4rem;

      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        font-size: 1.6rem;
      }
    }

    .upload-image {
      margin: 2rem;
      height: 10rem;
      width: 13rem;

      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        height: 15rem;
        width: 18rem;
      }
    }

    label {
      color: ${props => props.theme.palette.gray[700]};
      font-weight: 300;
      padding: 1.5rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .fileInput {
    display: none;
  }

  .preview {
    border-radius: 0.8rem;
    width: 40%;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4);
    margin-top: 1.5rem;
  }
`
