import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"
import jwtDecode from "jwt-decode"
import * as QueryString from "query-string"

import { Store, LoginStore } from "store"
import { LOGIN } from "store/reducers/loginReducer"
import localEnv from "config/env.js"

const { localStorageKey } = localEnv

export default ({ parent: Parent, child, roles, location, ...rest }) => {
  const [, dispatch] = useContext(LoginStore)
  const [{ token }] = useContext(Store)
  let localToken = localStorage.getItem(localStorageKey)

  const params = QueryString.parse(location.search)

  if (!localToken && !params.token) {
    toast.error("Acesso não autorizado!")
    return <Redirect to="/" />
  }

  if (roles) {
    if (!token.value && !params.token) {
      toast.error("Token de acesso inválido!")
      return <Redirect to="/" />
    }

    const actualToken = params.token ? params.token : token.value
    const { identity } = jwtDecode(actualToken)
    
    return roles.includes(identity.role) ? <Parent component={child} {...rest} /> : <Redirect to="/" />
  }

  dispatch({ type: LOGIN, payload: localToken })
  return <Parent component={child} {...rest} />
}
