import React from "react"
import { Route } from "react-router-dom"

import Container from "./styles"
import logo from "assets/logo.svg"

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Container>
          <div className="header"><img src={logo} alt="InovaMind" className="logo" /></div>

          <Component {...matchProps} />
        </Container>
      )}
    />
  )
}
