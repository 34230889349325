import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .type {
    width: 100%;
    max-width: 27rem;
  }

  .error {
    color: ${props => props.theme.palette.red};
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .loading-message {
    font-size: 1.6rem;
    margin-top: 2rem;
    color: ${props => props.theme.palette.gray[500]};
  }
`
