export const roles = {
  CAN_VERIFY_PHONE: "can_verify_phone",
  CAN_INSERT_SMS_TOKEN: "can_insert_sms_token",
  CAN_CONSULT_CEP: "can_consult_cep",
  CAN_SEND_ADDRESS: "can_send_address",
  CAN_SEND_DOC_FRONT: "can_send_doc_front",
  CAN_SEND_DOC_BACK: "can_send_doc_back",
  CAN_SEND_ADDRESS_DOC: "can_send_address_doc",
  CAN_COMPLETE: "can_complete",
  CAN_GET_QUIZ: "can_get_quiz",
  CAN_ANSWER_QUIZ: "can_answer_quiz",
  CAN_RETRY_QUIZ: "can_retry_quiz",
  CAN_NOT_COMPLETE: "can_not_complete",
  CAN_SEND_FIRST_LIVENESS_FRAME: "can_send_first_liveness_frame",
  CAN_SEND_LIVENESS: "can_send_liveness",
  CAN_RETRY_LIVENESS: "can_send_liveness_2"
}
