import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .cameraContainer {
    width: 100%;
    max-width: 54rem;
    height: 100%;
    position: relative;
    overflow: hidden;

    .mask {
      position:absolute;
      top: 15%;
      left: 13%;
      width: 75%;
      height: 80%;
      box-shadow: 0px 0px 0px 999px rgba(0,0,0,.4);
      border-radius: 50%;
      border: 4px solid white;
      z-index: 9;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        top: 15%;
        left: 17.5%;
        width: 65%;
        height: 80%;
      }

      &.valid {
        border-color: ${props => props.theme.palette.green};
      }
    }
  }

  .cameraOverlay {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    color: white;

    &.off {
      display: none;
    }

    span {
      width: 90%;
      margin-top: 1rem;
    }
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  .loadingLoader {
    color: ${props => props.theme.palette.gray[500]};
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  canvas {
    display: none;
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }
`
