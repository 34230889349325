import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .codeContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;

    input {
      border: 1px solid ${props => props.theme.palette.gray[500]};
      font-size: 2.4rem;
      height: 5rem;
      width: 4rem;
      text-align: center;
      border-radius: 0.4rem;
      -webkit-appearance: none;
      outline: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      input {
        width: 5rem;
      }
    }
  }

  .error {
    color: ${props => props.theme.palette.red};
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  button {
    margin-bottom: 2rem;
  }

  .hint {
    font-size: 1rem;
    color: ${props => props.theme.palette.gray[600]};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .hint {
      font-size: 1.1rem;
    }
  }

  .resend_sms {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${props => props.theme.palette.gray[500]};
    display: flex;
    margin-top: 2rem;

    .countdown_timer {
      padding: 0 0.5rem;
    }
  }

  .resend_sms_button {
    button {
      margin-top: 2rem;
      margin-bottom: 0;
      padding: 1rem 3rem;
      font-size: 1.4rem;
      font-weight: 500;
      border: 0;
      background-color: ${props => props.theme.palette.gray[300]};
      outline: none;
      cursor: pointer;
      color: black;
    }
  }
`
