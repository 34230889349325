import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-bottom: 2rem;
  }

  .question {
    width: 100%;
    position: relative;

    .MuiTypography-body1 {
      font-size: 1.2rem;

      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        font-size: 1.4rem;
      }
    }

    .MuiRadio-colorSecondary.Mui-checked {
      color: ${props => props.theme.palette.secondary};
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loaderContainer {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    color: ${props => props.theme.palette.gray[500]};
  }

  .error {
    color: ${props => props.theme.palette.red};
    font-size: 1.4rem;
    text-align: center;
  }

  .counter {
    position: absolute;
    right: 0;
    top: 3rem;
    font-size: 20px;
  }
`
